// components
import Iconify from "../../../components/Iconify";
import settings from "../../../settings";

// ----------------------------------------------------------------------


const ICONS = {
  user: <Iconify icon='carbon:user-avatar'/> ,
  branch: <Iconify icon='cil:library-building'/>,
  department: <Iconify icon='ci:building'/>,
  sessions: <Iconify icon='fluent:time-picker-20-filled'/>,
  home: <Iconify icon='ant-design:home-outlined'/>,
  students: <Iconify icon='carbon:user-profile' />,
  pendingStudents:<Iconify icon='fluent-mdl2:leave-user' />,
  outgoingStudents:<Iconify icon='fluent-mdl2:follow-user' />,
  dailyReports: <Iconify icon='carbon:report'/>,
  monthlyReports: <Iconify icon='heroicons-outline:document-report'/>,
  combineReports: <Iconify icon='codicon:combine'/>,
  registration: <Iconify icon='material-symbols:app-registration'/>,
  oldList: <Iconify icon='bi:card-list'/>,
  data: <Iconify icon='carbon:user-avatar'/>,
  payment: <Iconify icon='material-symbols:payments-outline'/>,
  shift: <Iconify icon='fluent:shifts-30-minutes-20-regular'/>,
  shifts: <Iconify icon='fluent:shifts-team-20-regular'/>,
  notification: <Iconify icon='carbon:notification-new' />,
  dashboard: <Iconify icon='carbon:dashboard'/>,
  price: <Iconify icon='ic:outline-price-change'/>,
  exchange: <Iconify icon='ic:twotone-currency-exchange'/>,
  receiptNumber: <Iconify icon='octicon:number-24'/>,
  discount: <Iconify icon='nimbus:discount-circle'/>,
  searchAndAcceptPay: <Iconify icon='fa-solid:search-dollar'/>,
  pendingPayment: <Iconify icon='mdi:cash-clock'/>,
  submitShift: <Iconify icon='carbon:document-import'/>,
  dailyOperation: <Iconify icon='icon-park-outline:reverse-operation-in'/>,
  cashInReport: <Iconify icon='mdi:cash-clock'/>,
  receiptReport: <Iconify icon='ion:receipt-outline'/>,
  submitReceipt: <Iconify icon='mdi:printer-receipt-cog-outline'/>,
  dropOutSchedule: <Iconify icon='material-symbols:arrow-drop-down-circle-outline'/>,
  changeBranchSchedule: <Iconify icon='pepicons-pop:rewind-time'/>
};

const sidebarConfig = [
  {
    items: [
      { title: 'home', path: '/', icon: ICONS.home },
    ]
  },
  // Receptionist
  {
    subheader: 'cashier.name',
    items: [
      { title: 'cashier.shifts', path: '/admin/shifts', icon: ICONS.shifts },
      { title: 'cashier.paymentDueDateNotification', path: '/admin/payment-notification', icon: ICONS.notification },
      { title: 'operation.paymentHistory', path: '/admin/search-and-accept-pay', icon: ICONS.searchAndAcceptPay, roles: [settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.branchManager, settings.realmRoles.adminSupervisor] },
      { title: 'cashier.searchAndAcceptMoney', path: '/admin/search-and-accept-pay', icon: ICONS.searchAndAcceptPay, roles: [settings.realmRoles.adminReceptionist] },
      { title: 'cashier.pendingPayment', path: '/admin/pending-payment', icon: ICONS.pendingPayment },
      { title: 'operation.submitReceipts', path: '/receipt/submit', icon: ICONS.submitReceipt },
    ],
  },
  // branch manager
  {
    subheader: 'operation.name',
    items: [
      { title: 'operation.submittedTimeShift', path: '/operation/submit-time-shift', icon: ICONS.submitShift, roles: [settings.realmRoles.branchManager] },
      { title: 'operation.dailyOperation', path: '/operation/daily-operation', icon: ICONS.dailyOperation, roles: [settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.branchManager, settings.realmRoles.adminSupervisor]},
      { title: 'operation.cashInReport', path: '/operation/cash-in-report', icon: ICONS.cashInReport, roles: [settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.branchManager, settings.realmRoles.adminSupervisor]},
    ],
  },
  // student
  // ----------------------------------------------------------------------
  {
    subheader: 'student.students',
    items: [
      { title: 'admin.registration', path: '/students/register', icon: ICONS.registration, roles: [settings.realmRoles.adminReceptionist]},
      { title: 'student.list', path: '/students', icon: ICONS.students },
      { title: 'student.pendingToNewBranch', path: '/students/status/PENDING_TO_NEW_BRANCH', icon: ICONS.pendingStudents },
      { title: 'student.outToNewBranch', path: '/students/status/OUT_TO_NEW_BRANCH', icon: ICONS.outgoingStudents },
      { title: 'student.changeBranchSchedules', path: '/change-branch-schedule', icon: ICONS.changeBranchSchedule },
      { title: 'student.dropOutSchedules', path: '/drop-out-schedule', icon: ICONS.dropOutSchedule },
      { title: 'student.oldList', path: '/students/old-list', icon: ICONS.oldList },
      {
        title: 'student.data',
        icon: ICONS.data,
        children: [
          { title: 'student.summaryReport', path: '/students/summary-report'},
          { title: 'student.listByMonth', path: '/students/status/CURRENT_REPORT'},
          { title: 'student.newStudent', path: '/students/status/NEW'},
          { title: 'student.reEnroll', path: '/students/status/RE_ENROLL'},
          { title: 'student.addHour', path: '/students/status/CHANGE_HOURS'},
          { title: 'student.changeBranch', path: '/students/status/CHANGE_BRANCH'},
          { title: 'student.moveIn', path: '/students/status/MOVE_IN'},
          { title: 'student.dropOff', path: '/students/status/DROP_OFF'},
          { title: 'student.graduated', path: '/students/status/GRADUATED'}
        ]
      },
    ],
  },
  // report
  {
    subheader: 'report.reports',
    items: [
      { title: 'report.receiptReport', path: '/reports/receipt', icon: ICONS.receiptReport },
      { title: 'report.monthly', path: '/reports/monthly', icon: ICONS.monthlyReports, roles: [settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.branchManager, settings.realmRoles.adminSupervisor] },
      { title: 'report.combine', path: '/reports/combined', icon: ICONS.combineReports, roles: [settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor]}
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management.name',
    items: [
      {
        title: 'schoolBranch.branches',
        path: '/school-branches',
        icon: ICONS.branch,
        roles: [settings.realmRoles.owner, settings.realmRoles.financeManager]
      },
      {
        title: 'department.departments',
        path: '/departments',
        icon: ICONS.department,
        roles: [settings.realmRoles.owner, settings.realmRoles.financeManager]
      },
      {
        title: 'session.sessions',
        path: '/sessions',
        icon: ICONS.sessions,
        roles: [settings.realmRoles.owner, settings.realmRoles.financeManager]
      },
      {
        title: 'price.prices',
        path: '/prices',
        roles: [settings.realmRoles.owner, settings.realmRoles.financeManager],
        icon: ICONS.price
      },
      {
        title: 'discount.discounts',
        path: '/discounts',
        roles: [settings.realmRoles.owner, settings.realmRoles.financeManager],
        icon: ICONS.discount
      },
      {
        title: 'exchangeRate.exchangeRates',
        roles: [settings.realmRoles.owner, settings.realmRoles.financeManager],
        path: '/exchange-rates',
        icon: ICONS.exchange,
      },
      {
        title: 'receiptNumber.receiptNumber',
        roles: [settings.realmRoles.owner, settings.realmRoles.financeManager],
        path: '/receipt-number',
        icon: ICONS.receiptNumber,
      },
      {
        title: 'user.users',
        path: '/users',
        icon: ICONS.user,
        roles: [settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor, settings.realmRoles.branchManager]
      },
    ],
  },
];

export default sidebarConfig;
