import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from "../guards/RoleBasedGuard";
import settings from '../settings';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      element: <DashboardLayout />,
      children: [
        {path: '/', element: <Home />, index: true}
        // {path: '/view-receipt/:id', element: <PublicReceipt />, index: true},
      ]
    },
    {
      element: <DashboardLayout />,
      children: [
        { path: '/users', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor, settings.realmRoles.branchManager]}><Users /></RoleBasedGuard>},
        { path: '/users/new', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor, settings.realmRoles.branchManager]}><UserNew /></RoleBasedGuard>},
        { path: '/users/edit/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor, settings.realmRoles.branchManager]}><UserEdit /></RoleBasedGuard>},
        { path: '/users/profile/:id', element: <RoleBasedGuard><UserEdit /></RoleBasedGuard>},
        { path: '/school-branches', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><SchoolBranches /></RoleBasedGuard>},
        { path: '/school-branches/new', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><SchoolBranchNew /></RoleBasedGuard>},
        { path: '/school-branches/edit/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><SchoolBranchEdit /></RoleBasedGuard>},
        { path: '/departments', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><Departments /></RoleBasedGuard>},
        { path: '/departments/new', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><DepartmentsNew /></RoleBasedGuard>},
        { path: '/departments/edit/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><DepartmentEdit /></RoleBasedGuard>},
        { path: '/sessions', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><Sessions /></RoleBasedGuard>},
        { path: '/sessions/new', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><SessionNew /></RoleBasedGuard>},
        { path: '/sessions/edit/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><SessionEdit /></RoleBasedGuard>},
        { path: '/exchange-rates', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><ExchangeRates /></RoleBasedGuard>},
        { path: '/exchange-rates/new', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><ExchangeRatesNew /></RoleBasedGuard>},
        { path: '/exchange-rates/edit/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><ExchangeRatesEdit /></RoleBasedGuard>},
        { path: '/prices', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><Prices /></RoleBasedGuard>},
        { path: '/prices/new', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><PricesNew /></RoleBasedGuard>},
        { path: '/prices/edit/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><PricesEdit /></RoleBasedGuard>},
        { path: '/discounts', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><Discounts/></RoleBasedGuard>},
        { path: '/discounts/new', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><DiscountsNew /></RoleBasedGuard>},
        { path: '/discounts/edit/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager]}><DiscountsEdit /></RoleBasedGuard>},
        { path: '/students', element: <RoleBasedGuard><Students/></RoleBasedGuard>},
        { path: '/students/status/:status', element: <RoleBasedGuard><Students/></RoleBasedGuard>},
        { path: '/students/summary-report', element: <RoleBasedGuard><StudentSummaryReport/></RoleBasedGuard>},
        { path: '/students/register', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.adminReceptionist]}><StudentsRegister /></RoleBasedGuard>},
        { path: '/students/edit/:departmentId/:id', element: <RoleBasedGuard><StudentsRegister /></RoleBasedGuard>},
        { path: '/students/old-list', element: <RoleBasedGuard><ReEnrollStudents /></RoleBasedGuard>},
        { path: '/students/re-enroll/edit/:departmentId/:id', element: <RoleBasedGuard><ReEnrollStudentEdit /></RoleBasedGuard>},
        { path: '/students/re-enroll-graduated/edit/:departmentId/:id', element: <RoleBasedGuard><ReEnrollGraduatedStudentEdit /></RoleBasedGuard>},
        { path: '/operation/daily-operation', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor, settings.realmRoles.branchManager]}><DailyOperation/></RoleBasedGuard>},
        { path: '/operation/daily-operation/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor, settings.realmRoles.branchManager]}><DailyOperationDetail/></RoleBasedGuard>},
        { path: '/operation/submit-time-shift', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.branchManager]}><TimeShift/></RoleBasedGuard>},
        { path: '/operation/cash-in-report', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor, settings.realmRoles.branchManager]}><CashInReport /></RoleBasedGuard>},
        { path: '/operation/cash-in-report/new', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.branchManager]}><CashInCreateEdit /></RoleBasedGuard>},
        { path: '/operation/cash-in-report/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor, settings.realmRoles.branchManager]}><CashInCreateEdit /></RoleBasedGuard>},
        { path: '/admin/shifts', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor, settings.realmRoles.branchManager, settings.realmRoles.adminReceptionist]}><TimeShift/></RoleBasedGuard>},
        { path: '/admin/shifts/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor, settings.realmRoles.branchManager, settings.realmRoles.adminReceptionist]}><TimeShiftDetail/></RoleBasedGuard>},
        { path: '/admin/search-and-accept-pay', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor, settings.realmRoles.branchManager, settings.realmRoles.adminReceptionist]}><StudentPayment/></RoleBasedGuard>},
        { path: '/admin/pending-payment', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor, settings.realmRoles.branchManager, settings.realmRoles.adminReceptionist]}><StudentPayment/></RoleBasedGuard>},
        { path: '/admin/accept-pay/:departmentId/:shiftId/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.adminReceptionist]}><Pay/></RoleBasedGuard>},
        { path: '/admin/accept-pay/:departmentId/:shiftId/:id/:paymentId', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.branchManager]}><Pay/></RoleBasedGuard>},
        { path: '/admin/payment-notification', element: <RoleBasedGuard><Notification /></RoleBasedGuard>},
        { path: '/receipt/submit', element: <RoleBasedGuard><SubmitReceipts /></RoleBasedGuard>},
        { path: '/receipt/submit/:id', element: <RoleBasedGuard><SubmitReceipt /></RoleBasedGuard>},
        { path: '/receipt/:id', element: <RoleBasedGuard><Receipt /></RoleBasedGuard>},
        { path: '/reports/receipt', element: <RoleBasedGuard><ReceiptReports /></RoleBasedGuard>},
        { path: '/reports/monthly', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor, settings.realmRoles.branchManager]}><MonthlyReports /></RoleBasedGuard>},
        { path: '/reports/monthly/new', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.branchManager]}><MonthlyReportsCreateEdit /></RoleBasedGuard>},
        { path: '/reports/monthly/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.financeManager, settings.realmRoles.branchManager, settings.realmRoles.manageFinanceReport]}><MonthlyReportsCreateEdit /></RoleBasedGuard>},
        { path: '/reports/monthly/detail/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor, settings.realmRoles.branchManager]}><MonthlyReportsCreateEdit /></RoleBasedGuard>},
        { path: '/reports/combined', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor]}><CombinedReports /></RoleBasedGuard>},
        { path: '/reports/combined/new', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.financeManager]}><CombinedReportsCreateEdit /></RoleBasedGuard>},
        { path: '/reports/combined/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.financeManager, settings.realmRoles.owner]}><CombinedReportsCreateEdit /></RoleBasedGuard>},
        { path: '/reports/combined/detail/:id', element: <RoleBasedGuard accessibleRoles={[settings.realmRoles.owner, settings.realmRoles.financeManager, settings.realmRoles.adminSupervisor]}><CombinedReportsCreateEdit /></RoleBasedGuard>},
        { path: '/payment-history/:studentId', element: <RoleBasedGuard><PaymentHistory /></RoleBasedGuard>},
        { path: '/drop-out-schedule', element: <RoleBasedGuard><DropOutSchedule /></RoleBasedGuard>},
        { path: '/change-branch-schedule', element: <RoleBasedGuard><ChangeBranchSchedule /></RoleBasedGuard>},
        { path: '/receipt-number', element: <RoleBasedGuard><ReceiptNumber /></RoleBasedGuard>},
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const Home = Loadable(lazy(() => import('../pages/Home')));
const Users = Loadable(lazy(() => import('../pages/users/Users')));
const UserNew = Loadable(lazy(() => import('../pages/users/CreateEdit')));
const UserEdit = Loadable(lazy(() => import('../pages/users/CreateEdit')));
const SchoolBranches = Loadable(lazy(() => import('../pages/schoolBranches/SchoolBranches')));
const SchoolBranchNew = Loadable(lazy(() => import('../pages/schoolBranches/CreateEdit')));
const SchoolBranchEdit = Loadable(lazy(() => import('../pages/schoolBranches/CreateEdit')));
const Departments = Loadable(lazy(() => import('../pages/departments/Departments')));
const DepartmentsNew = Loadable(lazy(() => import('../pages/departments/CreateEdit')));
const DepartmentEdit = Loadable(lazy(() => import('../pages/departments/CreateEdit')));
const Sessions = Loadable(lazy(() => import('../pages/sessions/Sessions')));
const SessionNew = Loadable(lazy(() => import('../pages/sessions/CreateEdit')));
const SessionEdit = Loadable(lazy(() => import('../pages/sessions/CreateEdit')));
const ExchangeRates = Loadable(lazy(() => import('../pages/exchangeRates/ExchangeRates')));
const ExchangeRatesNew = Loadable(lazy(() => import('../pages/exchangeRates/CreateEdit')));
const ExchangeRatesEdit = Loadable(lazy(() => import('../pages/exchangeRates/CreateEdit')));
const Prices = Loadable(lazy(() => import('../pages/prices/Prices')));
const PricesNew = Loadable(lazy(() => import('../pages/prices/CreateEdit')));
const PricesEdit = Loadable(lazy(() => import('../pages/prices/CreateEdit')));
const Discounts = Loadable(lazy(() => import('../pages/discounts/Discounts')));
const DiscountsNew = Loadable(lazy(() => import('../pages/discounts/CreateEdit')));
const DiscountsEdit = Loadable(lazy(() => import('../pages/discounts/CreateEdit')));
const Students = Loadable(lazy(() => import('../pages/students/Students')));
const StudentSummaryReport = Loadable(lazy(() => import('../pages/students/SummaryReport')));
const StudentsRegister = Loadable(lazy(() => import('../pages/students/CreateEdit')));
const ReEnrollStudents = Loadable(lazy(() => import('../pages/students/Students')));
const ReEnrollStudentEdit = Loadable(lazy(() => import('../pages/students/CreateEdit')));
const ReEnrollGraduatedStudentEdit = Loadable(lazy(() => import('../pages/students/CreateEdit')));
const DailyOperation = Loadable(lazy(() => import('../pages/dailyOperations/DailyOperations')));
const DailyOperationDetail = Loadable(lazy(() => import('../pages/dailyOperations/Detail')));
const CashInReport = Loadable(lazy(() => import('../pages/cashInReports/CashInReports')));
const CashInCreateEdit = Loadable(lazy(() => import('../pages/cashInReports/CreateEdit')));
const TimeShift = Loadable(lazy(() => import('../pages/timeShifts/TimeShifts')));
const TimeShiftDetail = Loadable(lazy(() => import('../pages/timeShifts/Detail')));
const StudentPayment = Loadable(lazy(() => import('../pages/payments/Students')));
const Pay = Loadable(lazy(() => import('../pages/payments/Pay')));
const Receipt = Loadable(lazy(() => import('../pages/receipt/Receipt')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Notification = Loadable(lazy(() => import('../pages/dueDateNotification/index')));
const MonthlyReports = Loadable(lazy(() => import('../pages/monthlyReports/Index')));
const MonthlyReportsCreateEdit = Loadable(lazy(() => import('../pages/monthlyReports/CreateEdit')));
const CombinedReports = Loadable(lazy(() => import('../pages/combinedReports/Index')));
const CombinedReportsCreateEdit = Loadable(lazy(() => import('../pages/combinedReports/CreateEdit')));
const PaymentHistory = Loadable(lazy(() => import('../pages/payments/paymentHistory')));
const ReceiptReports = Loadable(lazy(() => import('../pages/receipt/ReceiptReports')));
const SubmitReceipts = Loadable(lazy(() => import('../pages/receipt/SubmitReceipts')));
const SubmitReceipt = Loadable(lazy(() => import('../pages/receipt/SubmitReceipt')));
const DropOutSchedule = Loadable(lazy(() => import('../pages/dropOutSchedules/DropOutSchedules')));
const ChangeBranchSchedule = Loadable(lazy(() => import('../pages/changeBranchSchedules/ChangeBranchSchedules')));
const ReceiptNumber = Loadable(lazy(() => import('../pages/receiptNumber/ReceiptNumber')));
